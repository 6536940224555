export const isNotSafariOnAppleDevice = () => {
  const is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  const is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

  const is_Mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const is_iPhone = navigator.platform === "iPhone";
  const is_iPod = navigator.platform === "iPod";
  const is_iPad = navigator.platform === "iPad";

  var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

  return !isSafari && (is_OSX || is_iOS || is_Mac || is_iPhone || is_iPod || is_iPad);
}

const getDiscount = (liters, waterMachineData) => {
  const discountItem = waterMachineData.discounts.find(
    (item) => liters >= item.startRange && liters < item.endRange
  );

  return discountItem ? discountItem.discount : 0;
}

export const getLitersByMoney = (money, waterMachineData) => {
  const moneyInCoins = money * 100;
  const liters = moneyInCoins / waterMachineData.cost;
  const discount = getDiscount(liters, waterMachineData);

  return {
    liters: liters.toFixed(1),
    discount
  }
}

export const getMoneyByLiters = (liters, waterMachineData) => {
  const money = (liters * waterMachineData.cost) / 100;
  const discount = getDiscount(liters, waterMachineData);

  return {
    money: money.toFixed(2),
    discount
  }
}

export const getMoneyWithDiscount = (money, discount) => {
  const moneyInCoins = Number(money) * 100;

  return money ? Number((moneyInCoins - (moneyInCoins * (discount / 100))) / 100).toFixed(2) : null;
};