import { waterAmountLabel } from "./formats";

export const getMessageNoEnoughWater = (maxLiters) => {
  return `За один раз водомат може продати не бiльше ${maxLiters} ${waterAmountLabel(
    maxLiters
  )} води.`;
};

export const getDiscountMessage = (discount) => {
  return `Ваша знижка ${discount}%`;
};

export const getMessageMinMoneyAmount = (minMoney, currentMoney) => {
  return `Мiнiмальна сума платежу ${minMoney} грн. Бажаєте округлити ${currentMoney} грн до ${minMoney} грн та продовжити?`;
}

export const getMessageFormIsEmpty = () => {
  return 'Натиснiть на кнопку з бажанною кiлькiстю лiтрiв або скористайтеся формою вводу. Та натиснiть "Сплатити".';
}