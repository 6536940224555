import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../Loader";
import { actionsAfterPayment } from '../../actions/app';

const PageSendWaterToWaterMachine = ({
  waterMachineDataIsLoading,
  waterMachineDataLoadingMessage
}) => {
  const { uuid: sessionUuid } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsAfterPayment(sessionUuid));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return waterMachineDataIsLoading ? <Loader description={waterMachineDataLoadingMessage} /> : null
}

PageSendWaterToWaterMachine.propTypes = {
  waterMachineDataIsLoading: PropTypes.bool,
  waterMachineDataLoadingMessage: PropTypes.string,
};

export default PageSendWaterToWaterMachine;

