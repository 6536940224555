import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { ToastContainer, toast, Slide } from 'react-toastify';

import PredefinedLitersButton from '../PredefinedLitersButton';
import ModalWindow from "../ModalWindow";
import Loader from "../Loader";
import Logo from "../Logo";

import {
  isLitersValid,
  isMoneyValid,
  getValidationMessage,
  transformValueToValid,
} from "../../utils/validation";
import { waterAmountLabel } from "../../utils/formats";
import { getSupportLink, getAnotherWaterMachineLink } from '../../constants/routes';
import { prepareToPayment } from '../../actions/app';

import { getLitersByMoney, getMoneyByLiters, getMoneyWithDiscount, isNotSafariOnAppleDevice } from './utils';
import { DiscountsModalBody } from './DiscountsModalBody';
import { 
  MIN_MONEY_AMOUNT,
  MAX_LITERS,
  PREDEFINED_LITERS,
  AUTO_CLOSE_TOAST,
  MESSAGE_TYPES,
} from '../../constants';

const PageMain = React.memo(({
  waterMachineData,
  waterMachineDataIsLoading,
  waterMachineDataLoadingMessage,
}) => {
  const dispatch = useDispatch();
  const [modalWindowRoundMoney, setModalWindowRoundMoney] = useState(null);
  const [modalWindowDiscounts, setModalWindowDiscounts] = useState(false);
  const [inputsData, setInputsData] = useState({
    money: "",
    liters: "",
    discount: "",
  });

  const moneyWithDiscount = useMemo(
    () => getMoneyWithDiscount(
      inputsData.money,
      inputsData.discount
    ), 
  [inputsData.money, inputsData.discount]);

  const inputObjectAfterLitersChange = (liters) => {
    if (isLitersValid(liters)) {
      const { discount, money } = getMoneyByLiters(
        liters,
        waterMachineData
      );

      return {
        ...inputsData,
        money,
        liters,
        discount,
      };
    }

    return inputsData;
  }

  const inputObjectAfterMoneyChange = (money) => {
    if (isMoneyValid(money)) {
      const { discount, liters } = getLitersByMoney(
        money,
        waterMachineData
      );

      return {
        ...inputsData,
        money,
        liters,
        discount,
      };
    }

    return inputsData;
  }

  const onChangeLiters = (event) => {
    const liters = transformValueToValid(event.target.value);

    setInputsData(inputObjectAfterLitersChange(liters));
  };

  const onChangeMoney = (event) => {
    const money = transformValueToValid(event.target.value);

    setInputsData(inputObjectAfterMoneyChange(money));
  };

  const onPredefinedButtonClick = (liters) => {
    setInputsData(inputObjectAfterLitersChange(liters));
  }

  const onClickPay = () => {
    const money = moneyWithDiscount;
    const { discount, liters } = inputsData;
    const validation = getValidationMessage(discount, liters, MAX_LITERS, money, MIN_MONEY_AMOUNT);

    if (validation && validation.messageType !== MESSAGE_TYPES.skip) {
      switch (validation.messageType) {
        case MESSAGE_TYPES.toast: 
          toast[validation.appearance](validation.message, { toastId: validation.id });
          break;
        case MESSAGE_TYPES.modal:
          setModalWindowRoundMoney({
            content: validation.message,
            onApply: () => {
              dispatch(prepareToPayment(waterMachineData, { ...inputsData, money: MIN_MONEY_AMOUNT.toString() }));
            }
          });
          break;
        default:
          return;
      }

    } else {
      dispatch(prepareToPayment(waterMachineData, { ...inputsData, money }));
    }
  }

  const onOpenDiscountsModalWindow = () => {
    setModalWindowDiscounts(true);
  }

  useEffect(() => {
    if (isNotSafariOnAppleDevice() && waterMachineData) {
      toast.info(<>Якщо ви бажаєте розрахуватися через <b>Apple Pay</b>. Будь ласка скористайтеся браузером <b>Safari</b>.</>);
    }
  }, [waterMachineData]);

  return (
    <>
      {waterMachineDataIsLoading ? <Loader description={waterMachineDataLoadingMessage} /> : null}
      {waterMachineData ? (
        <>
          <Logo />
          <header className="box">
            <h1 className="mt-0-rem">Придбання води</h1>
            <p className="font-size-smaller">Водомат &#8470; <b>{waterMachineData.automateNumber}</b></p>
            <p className="font-size-smaller">{`${waterMachineData.town} ${waterMachineData.street}, ${waterMachineData.build}`}</p>
            <p className="font-size-smaller">Вартiсть 1 лiтри води - {waterMachineData.cost / 100} грн</p>
            { 
              waterMachineData.discounts ? (
                <>
                  <span className="mb-0-rem link" onClick={onOpenDiscountsModalWindow}>Знижки</span>&nbsp;&#8729;&nbsp;
                </>
              ) : null }
            <span className="mb-0-rem link">Інструкція</span>
          </header>

          <main className="box mb-1-rem">

            <div className="predefined-liters-wrapper">
              {
                PREDEFINED_LITERS.map((item) => {
                  const { discount } = getMoneyByLiters(
                    item.value,
                    waterMachineData
                  );

                  return (
                    <PredefinedLitersButton 
                      key={item.id} 
                      value={`${item.value} ${waterAmountLabel(Number(item.value))}`} 
                      discount={discount}
                      onPredefinedButtonClick={() => onPredefinedButtonClick(item.value)}
                    />
                  );
                })
              }
            </div>
            <div className="input-form">
              <div>
                <label htmlFor="input-water-amount" className="required-field">Кiлькiсть лiтрiв</label>
                <div className="form-group">
                  <div className="form-group-input">
                    <input
                      value={inputsData.liters}
                      onChange={onChangeLiters}
                      placeholder="0"
                      id="input-water-amount"
                      type="text"
                      inputMode="decimal"
                      required
                    />
                  </div>
                  <div className="form-group-amount">
                    {waterAmountLabel(inputsData.liters)}
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="input-money-amount" className="required-field">Cума грошей</label>
                <div className="form-group">
                  <div className="form-group-input">
                    <input
                      value={inputsData.money}
                      onChange={onChangeMoney}
                      placeholder="0"
                      id="input-money-amount"
                      type="text"
                      inputMode="decimal"
                      required
                    />
                  </div>
                  <div className="form-group-amount">грн</div>
                </div>
              </div>
            </div>

            <PredefinedLitersButton
              value={`Оплатити ${moneyWithDiscount ? ` ${moneyWithDiscount} грн` : ''}`}
              discount={inputsData.discount}
              onPredefinedButtonClick={() => onClickPay()}
            />
          </main>
          <p className="ta-center">
            <Link to={getAnotherWaterMachineLink(waterMachineData.automateNumber)}>Придбати воду на іншому водоматі</Link>
          </p>
          <p className="ta-center pb-1-rem">
            <Link to={getSupportLink(waterMachineData.automateNumber)}>Технічна підтримка</Link>
          </p>
        </>
      ) : null}
      <ToastContainer autoClose={AUTO_CLOSE_TOAST} position="bottom-center" transition={Slide} hideProgressBar />
      { modalWindowRoundMoney?.content && modalWindowRoundMoney?.onApply ? (
          <ModalWindow
            title={`Мінімальна сума платежу ${MIN_MONEY_AMOUNT} грн`}
            onApply={() => modalWindowRoundMoney.onApply()}
            onClose={() => setModalWindowRoundMoney(null)}
            msgApplyBtn="Погоджуюся"
            msgCloseBtn="Закрити"
          >
            <div className="box">
              {modalWindowRoundMoney?.content}
            </div>
          </ModalWindow>
        ) : null 
      }
      {
        modalWindowDiscounts ? (
          <ModalWindow
            title="Знижки"
            onClose={() => setModalWindowDiscounts(false)}
            msgCloseBtn="Закрити"
          >
            <DiscountsModalBody
              waterMachineNumber={waterMachineData.automateNumber}
              discounts={waterMachineData.discounts}
            />
          </ModalWindow>
        ) : null
      }
    </>
  );
});

PageMain.propTypes = {
  waterMachineData: PropTypes.object,
  waterMachineDataIsLoading: PropTypes.bool,
  waterMachineDataLoadingMessage: PropTypes.string,
  waterMachineDataErrorPaymentSuccess: PropTypes.bool,
};

export default PageMain;
