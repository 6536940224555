import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";

import { getSupportLink } from "../../constants/routes";

import Logo from "../Logo";

const PageInfo = ({ 
  textHeader,
  textDescription,
  type,
  backButton,
  automateNumber,
  supportLink,
  children,
  callBackOnActions,
}) => {
  return (
    <>
      <div className="page-info-wrapper">
        <Logo />
        <div
          className={cx(
            {
              info: type === "info",
              error: type === "error",
              success: type === "success",
              warning: type === "warning",
            }
          )}
        >
          <div className="box ta-center">
            <h1 className="page-info-wrapper-header">{textHeader}</h1>
            <p className="page-info-wrapper-description">
              {textDescription}
            </p>
            {
              children ? children : null
            }
            {
              supportLink ? (
                <p onClick={() => callBackOnActions()} className="ta-center mb-0-rem">
                  <Link to={getSupportLink(automateNumber)}>Технічна підтримка</Link>
                </p>
              ) : null
            }
          </div>
          {
            backButton && automateNumber ? (
              <p onClick={() => callBackOnActions()} className="error page-info-wrapper-contacts">
                <Link to={`/${automateNumber}`}>
                  Повернутися на головну сторiнку водомату
                </Link>
              </p>
            ) : null
          }
        </div>
      </div>
    </>
  );
};

PageInfo.propTypes = {
  textHeader: PropTypes.string,
  textDescription: PropTypes.string,
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
  backButton: PropTypes.bool,
  automateNumber: PropTypes.number,
  supportLink: PropTypes.bool,
  callBackOnActions: PropTypes.func,
};

export default PageInfo;
