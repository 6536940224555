import { SET_APP_ERROR, SET_APP_LOADING, SET_APP_DATA, SET_APP_PAYMENT_SUCCESS } from '../actions/app';

const initialState = {
  error: '',
  isLoading: false,
  loadingMessage: '',
  waterMachineData: null,
  paymentSuccess: false,
}

export const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_DATA:
      return {
        ...state,
        waterMachineData: action.payload,
      }
    case SET_APP_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_APP_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        loadingMessage: action.payload.loadingMessage,
      }
    case SET_APP_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentSuccess: action.payload
      }
    }
    default:
      return state;
  }
}

export default app;