import React from "react";
import PropTypes from "prop-types";

const Loader = ({ description }) => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {description ? <p className="description">{description}</p> : null}
    </div>
  );
};

Loader.propTypes = {
  description: PropTypes.string,
};

export default Loader;
