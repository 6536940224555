export const statusesServer = {
  OK: "OK",
  SALES_STOPPED: "SALES_STOPPED",
  DOES_NOT_WORK: "DOES_NOT_WORK",
  CONNECTION_FAIL: "CONNECTION_FAIL",
  AUTOMATE_NOT_FOUND: "AUTOMATE_NOT_FOUND",
  PAYMENT_SESSION_NOT_FOUND: "PAYMENT_SESSION_NOT_FOUND",
  WATER_MACHINE_IS_NOT_RESPONDING: "WATER_MACHINE_IS_NOT_RESPONDING",
  PAYMENT_NOT_APPROVED: "PAYMENT_NOT_APPROVED"
};

export const statusesClient = {
  UNDEFINED_ERROR: "UNDEFINED_ERROR",
  NOT_ENOUGH_WATER: "NOT_ENOUGH_WATER",
  DECLINED_PAYMENT: "DECLINED_PAYMENT",
};
