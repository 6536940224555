import React from 'react';
import PropTypes from "prop-types";

const PredefinedLitersButton = ({ value, money, discount, amount, onPredefinedButtonClick }) => {
  return (
    <div className="predefined-liters">
      <button className="predefined-liters-button" onClick={() => onPredefinedButtonClick(money)}>
        <span className="predefined-liters-water-amount">{value}</span>
        { discount && discount > 0 ? <span className="predefined-liters-discount-amount">-{discount}%</span> : null }
      </button>
    </div>
  )
}

PredefinedLitersButton.propTypes = {
  value: PropTypes.string,
  money: PropTypes.string,
  discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PredefinedLitersButton;