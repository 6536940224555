export const routes = {
  waterMachine: "/:id",
  support: "/support",
  selectAnotherWaterMachine: '/change-water-machine',
  sessionUuid: "/:uuid",
};

export const getSupportLink = (id) => {
  return `/${id}${routes.support}`;
}

export const getAnotherWaterMachineLink = (id) => {
  return `/${id}${routes.selectAnotherWaterMachine}`
}