import { statusesServer, statusesClient } from "../constants/statuses";

export const errorHandling = (status) => {
  switch (status) {
    case statusesServer.PAYMENT_SESSION_NOT_FOUND:
    case statusesClient.UNDEFINED_ERROR:
      return "Помилка при отриманні даних з серверу.";
    case statusesServer.CONNECTION_FAIL:
      return "На данний момент водомат не доступний.";
    case statusesServer.SALES_STOPPED:
      return "На данний момент водомат не має можливості продати вам воду.";
    case statusesServer.DOES_NOT_WORK:
      return "На данний момент водомат не працює.";
    case statusesServer.AUTOMATE_NOT_FOUND:
      return "Водомат не знайдено. Спробуйте відсканувати QR код ще раз.";
    case statusesClient.NOT_ENOUGH_WATER:
      return "На даний момент у водоматі немає бажаної кількості води.";
    case statusesClient.DECLINED_PAYMENT:
      return "Помилка платежу. Платiж не здiйснено.";
    case statusesServer.PAYMENT_NOT_APPROVED:
      return "Гроші не було знято. Помилка платежу.";
    case statusesServer.WATER_MACHINE_IS_NOT_RESPONDING:
      return "Виникла помилка при зарахуваннi грошей на водомат. Будьласка звернiться до технiчної підтримки для повернення коштів.";
    default:
      return null;
  }
};
