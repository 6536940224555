import {
  getDiscountMessage,
  getMessageNoEnoughWater,
  getMessageMinMoneyAmount,
  getMessageFormIsEmpty
} from "../utils/messages";
import { MESSAGE_TYPES } from '../constants';

export const transformValueToValid = (value) => {
  if(value === ',' || value === '.') {
    return '';
  }

  return value.trim().replace().replace(',', '.');
}

export const isLitersValid = (value) => {
  return /^\d*(\.\d{0,1})?$/.test(value);
};

export const isMoneyValid = (value) => {
  return /^\d*(\.\d{0,2})?$/.test(value);
};

export const getValidationMessage = (discount, liters, maxLiters, money, minMoney) => {
  if (money && liters && Number(liters) !== 0 && Number(money) !== 0) {
    if (money < minMoney) {
      return {
        id: 1,
        message: getMessageMinMoneyAmount(minMoney, money),
        messageType: MESSAGE_TYPES.modal,
        appearance: "error"
      }
    }
    if (liters >= maxLiters) {
      return {
        id: 2,
        message: getMessageNoEnoughWater(maxLiters),
        messageType: MESSAGE_TYPES.toast,
        appearance: "error",
      };
    } else if (Number(discount) !== 0) {
      return {
        id: 3,
        message: getDiscountMessage(discount),
        messageType: MESSAGE_TYPES.skip,
        appearance: "success",
      };
    }
  } else {
    return {
      id: 4,
      message: getMessageFormIsEmpty(),
      appearance: 'info',
      messageType: MESSAGE_TYPES.toast,
    }
  };
};
