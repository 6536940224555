import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import PageInfo from "./components/PageInfo";

import { ContainerPageMain } from './containers/ContainerPageMain';

import { routes } from "./constants/routes";

import "./App.scss";

const App = () => {
  return (
    <div className="pageWrapper">
      <Router>
        <Switch>
          <Route path={routes.waterMachine}>
            <ErrorBoundary>
              <ContainerPageMain/>
            </ErrorBoundary>
          </Route>
          <Route>
            <PageInfo
              type="error"
              textHeader="Водомат не знайдено"
              textDescription="На жаль ми не можемо знайти данный водомат у нашiй системi."
            />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
