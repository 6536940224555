import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';

import Logo from "../Logo";
import Callout from "../Callout";
import Loader from "../Loader";

import { URL_OF_PAYMENT_PAGE } from '../../constants';
import { getWaterMachineWaterAvailableLink } from '../../constants';
import { statusesServer } from "../../constants/statuses";
import { AUTO_CLOSE_TOAST } from '../../constants';
import { getSupportLink } from "../../constants/routes";

export const isWaterMachineNumberValid = (value) => {
  return /^\d*(\d{0})?$/.test(value);
};

const PagePaymentAtAnotherWaterMachine = ({ currentAutomateNumber, supportLink }) => {
  const [automateNumber, setAutomateNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeWaterMachineNumber = (e) => {
    if (isWaterMachineNumberValid(e.target.value)) {
      setAutomateNumber(e.target.value);
    } else if (e.target.value === '') {
      setAutomateNumber('');
    }
  }

  const onMoveToAnotherWaterMachine = () => {
    setIsLoading(true);
    if (automateNumber && Number(automateNumber) !== currentAutomateNumber) {
      fetch(getWaterMachineWaterAvailableLink(automateNumber)).then((data) => {
        return data.json();
      }).then((data) => {
        if (statusesServer.OK !== data.status) {
          setIsLoading(false);
          toast.error('Данного водомату не існує або на він не доступний.');
        } else {
          window.location.replace(`${URL_OF_PAYMENT_PAGE}${automateNumber}`);
        }
      })
    } else if (Number(automateNumber) === currentAutomateNumber)  {
      setIsLoading(false);
      toast.info(<>Ви зараз використовуєте водомат &#8470; {automateNumber}. Натистніть на "Повернутися на головну сторiнку водомату".</>);
    } else {
      setIsLoading(false);
      toast.info('Помилка');
    }
  }

  return (
    <>
      { isLoading ? <Loader description="Завантаження даних водомату." /> : null }
      <Logo />
      <main className="box mb-1-rem">
        <Callout type="info" text='Якщо ви бажаєте скористатися іншим водоматом для оплати введіть його номер в поле вводу та натисніть "Перейди".' />
        <div className="mb-1-rem">
          <label htmlFor="water-machine-id" className="required-field">Номер водомату</label>
          <div className="form-group">
            <div className="form-group-input">
              <input
                value={automateNumber}
                onChange={onChangeWaterMachineNumber}
                placeholder="0"
                id="water-machine-id"
                type="text"
                inputMode="decimal"
                required
              />
            </div>
          </div>
        </div>
        <input 
          type="button"
          className="predefined-liters-button"
          disabled={!automateNumber}
          onClick={onMoveToAnotherWaterMachine}
          value="Перейти"
        />
      </main>
      {
        supportLink ? (
        <p className="ta-center mb-0-rem">
          <Link to={getSupportLink(currentAutomateNumber)}>Технічна підтримка</Link>
        </p>) : null
      }
      {
        currentAutomateNumber ? (
          <p className="error page-info-wrapper-contacts">
            <Link to={`/${currentAutomateNumber}`}>
              Повернутися на головну сторiнку водомату
            </Link>
          </p>
        ) : null
      }
      <ToastContainer autoClose={AUTO_CLOSE_TOAST} position="bottom-center" transition={Slide} hideProgressBar />
    </>
  );
}

PagePaymentAtAnotherWaterMachine.propTypes = {
  currentAutomateNumber: PropTypes.number,
  supportLink: PropTypes.bool,
};

export default PagePaymentAtAnotherWaterMachine;