import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, Switch, Route } from "react-router-dom";

import { fetchWaterMachineData, setAppPaymentSuccess, setAppError} from '../actions/app';
import Phones from "../components/Phones";
import PageInfo from "../components/PageInfo";
import Loader from "../components/Loader";
import PageMain from '../components/PageMain';
import PageSendWaterToWaterMachine from "../components/PageSendWaterToWaterMachine";
import PagePaymentAtAnotherWaterMachine from "../components/PagePaymentAtAnotherWaterMachine";

import { routes } from "../constants/routes";

export const ContainerPageMain = () => {
  const { id: waterMachineId } = useParams();
  const dispatch = useDispatch();
  const {
    waterMachineData, 
    isLoading: waterMachineDataIsLoading, 
    loadingMessage: waterMachineDataLoadingMessage,
    error: waterMachineDataError,
    paymentSuccess: waterMachineDataErrorPaymentSuccess,
  } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(fetchWaterMachineData(waterMachineId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (waterMachineDataError) {
    return (
      <PageInfo
        type="error"
        textHeader="Помилка"
        textDescription={waterMachineDataError}
        automateNumber={waterMachineData?.automateNumber}
        callBackOnActions={() => {
          dispatch(setAppError(''));
        }}
        supportLink={
          !!waterMachineData?.phones && 
          !!waterMachineData?.supportAvailable?.from && 
          !!waterMachineData?.supportAvailable?.to
        }
        backButton
      />
    );
  }

  if (waterMachineDataErrorPaymentSuccess) {
    return (
      <PageInfo
        type="success"
        textHeader="Оплата успiшна"
        textDescription="Встановіть пляшку в водомат і натисніть кнопку старт."
        automateNumber={waterMachineData?.automateNumber}
        callBackOnActions={() => {
          dispatch(setAppPaymentSuccess(false));
        }}
        supportLink
        backButton
      />
    )
  }

  return (
    <>
      { waterMachineDataIsLoading ? <Loader description={waterMachineDataLoadingMessage} /> : null }
      {
        waterMachineData ? (
          <Switch>
            <Route exact path={routes.waterMachine}>
              <PageMain 
                waterMachineData={waterMachineData}
                waterMachineDataIsLoading={waterMachineDataIsLoading}
                waterMachineDataLoadingMessage={waterMachineDataLoadingMessage}
              />
            </Route>
            <Route exact path={`${routes.waterMachine}${routes.selectAnotherWaterMachine}`}>
              <PagePaymentAtAnotherWaterMachine 
                currentAutomateNumber={waterMachineData?.automateNumber}
                supportLink
              />
            </Route>
            <Route exact path={`${routes.waterMachine}${routes.support}`}>
              <PageInfo
                type="info"
                textHeader="Технічна підтримка"
                automateNumber={waterMachineData?.automateNumber}
                backButton={
                  !!waterMachineData?.phones && 
                  !!waterMachineData?.supportAvailable?.from && 
                  !!waterMachineData?.supportAvailable?.to
                }
              >
                {
                  waterMachineData?.phones && 
                  waterMachineData?.supportAvailable?.from && 
                  waterMachineData?.supportAvailable?.to ? (
                    <Phones
                      from={waterMachineData.supportAvailable.from}
                      to={waterMachineData.supportAvailable.to}
                      phones={waterMachineData.phones}
                      viber={waterMachineData.viber}
                      telegram={waterMachineData.telegram}
                    />
                  ) : null
                }
              </PageInfo>
            </Route>
            <Route exact path={`${routes.waterMachine}${routes.sessionUuid}`}>
              <PageSendWaterToWaterMachine
                waterMachineDataIsLoading={waterMachineDataIsLoading}
                waterMachineDataLoadingMessage={waterMachineDataLoadingMessage}
              />
            </Route>
            <Route>
              <PageInfo
                type="error"
                textHeader="Неправильне посилання"
                textDescription="Відскануйте QR код водомату!"
                automateNumber={waterMachineData?.automateNumber}
                supportLink
                backButton
              />
            </Route>
          </Switch>
        ) : null
      }
    </>
  );
}