import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import "./index.css";
import App from "./App";
import rootReducer from "./reducers";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://839a08f36c1d4797a27d417c41be6e9d@o877446.ingest.sentry.io/5828070",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

const store = createStore(
  rootReducer, 
  enhancer,
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
