import React from 'react';
import PropTypes from 'prop-types';
import { MAX_LITERS } from '../../constants';

import Callout from "../Callout";

export const DiscountsModalBody = ({ waterMachineNumber, discounts }) => {
  console.log(discounts);
  return (
    <>
      <Callout type="info">
        Знижки відповідно до водомату &#8470; {waterMachineNumber}
      </Callout>
      <div className="box">
        {
          discounts.map((item) => {
            return (
              <p 
                key={item.discount}
              >
                Знижка <b>{item.discount}%</b> { item.startRange ? <>від <b>{item.startRange}л</b></> : null} до <b>{item.endRange > MAX_LITERS ? MAX_LITERS : item.endRange - 1}л</b>
              </p>
            );
          })
        }
      </div>
    </>
  )
};

DiscountsModalBody.propTypes = {
  waterMachineNumber: PropTypes.number.isRequired,
  discounts: PropTypes.arrayOf(PropTypes.shape({
    discount: PropTypes.number.isRequired,
    startRange: PropTypes.number.isRequired,
    endRange: PropTypes.number.isRequired,
  })).isRequired,
};