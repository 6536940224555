import { v4 as uuidv4 } from 'uuid';
import * as Sentry from "@sentry/react";

import { 
  getWaterMachineInfoLink,
  getWaterMachineWaterAvailableLink,
  getPaymentConfigLink,
  setMoneyIntoWaterMachineLink,
} from '../constants';
import { statusesClient, statusesServer } from '../constants/statuses';
import { errorHandling } from '../utils/errorsHandling';

export const SET_APP_DATA = 'SET_APP_DATA';
export const SET_APP_ERROR = 'SET_APP_ERROR';
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_APP_PAYMENT_SUCCESS = 'SET_APP_PAYMENT_SUCCESS';

export const setWaterMachineData = (payload) => {
  return {
    type: SET_APP_DATA,
    payload
  }
}

const setAppIsLoading = (payload) => {
  return {
    type: SET_APP_LOADING,
    payload
  }
}

export const setAppError = (payload) => {
  return { 
    type: SET_APP_ERROR,
    payload,
  }
}

export const setAppPaymentSuccess = (payload) => {
  return {
    type: SET_APP_PAYMENT_SUCCESS,
    payload
  }
}

const checkWaterMachineIsReadyToSell = async (dispatch, waterMachineData, inputsData) => {
  dispatch(setAppIsLoading({
    isLoading: true,
    loadingMessage: 'Перевiрка доступностi водомату.',
  }));

  try {
    const data = await fetch(getWaterMachineWaterAvailableLink(waterMachineData.automateNumber));
    const parsedData = await data.json();

    if (errorHandling(parsedData.status)) {
      Sentry.captureException({
        method: 'checkWaterMachineIsReadyToSell',
        status: errorHandling(parsedData.status),
      });

      return dispatch({ 
        type: SET_APP_ERROR,
        payload: errorHandling(parsedData.status),
      });
    }

    if (inputsData.liters >= parsedData.waterAvailable) {
      return dispatch({ 
        type: SET_APP_ERROR,
        payload: errorHandling(statusesClient.NOT_ENOUGH_WATER),
      });
    }
  } catch (e) {
    Sentry.captureException({
      method: 'checkWaterMachineIsReadyToSell',
      error: e,
    });

    return dispatch({ 
      type: SET_APP_ERROR,
      payload: errorHandling(statusesClient.UNDEFINED_ERROR),
    });
  } finally {
    dispatch(setAppIsLoading({
      isLoading: false,
      loadingMessage: '',
    }))
  }
}

const getPaymentConfig = async (dispatch, currentSessionUuid, waterMachineData, inputsData) => {
  dispatch(setAppIsLoading({
    isLoading: true,
    loadingMessage: 'Перенаправлення на платiжну сторiнку.',
  }));

  try {
    const data = await fetch(getPaymentConfigLink(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        currentSessionUuid,
        waterMachineData,
        inputsData
      })
    });

    const parsedData = await data.json();

    return parsedData;

  } catch (e) {
    dispatch({ 
      type: SET_APP_ERROR,
      payload: errorHandling(statusesClient.UNDEFINED_ERROR),
    });
  } finally {
    dispatch(setAppIsLoading({
      isLoading: false,
      loadingMessage: '',
    }));
  }
}

/* ---------------------------------------------------------------- */

export const fetchWaterMachineData = (waterMachineId) => {
  return async (dispatch) => {
    dispatch(setAppIsLoading({
      isLoading: true,
      loadingMessage: 'Завантаження даних водомату.',
    }));

    try {
      const data = await fetch(getWaterMachineInfoLink(waterMachineId));
      const parsedData = await data.json();

      if (parsedData.status) {
        dispatch({ 
          type: SET_APP_ERROR,
          payload: errorHandling(parsedData.status),
        });
      } else {
        dispatch(setWaterMachineData(parsedData));
      }
    } catch(e) {
      dispatch({ 
        type: SET_APP_ERROR,
        payload: errorHandling(statusesClient.UNDEFINED_ERROR),
      });
    } finally {
      dispatch(setAppIsLoading({
        isLoading: false,
        loadingMessage: '',
      }))
    }
  }
}

export function prepareToPayment (waterMachineData, inputsData) {
  return async (dispatch) => {
    const wayforpay = new window.Wayforpay();
    const currentSessionUuid = uuidv4();

    const checkResult = await checkWaterMachineIsReadyToSell(dispatch, waterMachineData, inputsData);

    if (checkResult?.type !== SET_APP_ERROR) {
      const paymentConfig = await getPaymentConfig(dispatch, currentSessionUuid, waterMachineData, inputsData);
  
      wayforpay.run(paymentConfig)
    }
  }
}

export function actionsAfterPayment (paymentSessionUuid) {
  return async (dispatch) => {
    dispatch(setAppIsLoading({
      isLoading: true,
      loadingMessage: 'Зарахування грошей на водомат.',
    }));

    try {
      const data =  await fetch(setMoneyIntoWaterMachineLink(), {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentSessionUuid,
        })
      });

      const parsedData = await data.json();

      switch(parsedData.status) {
        case statusesServer.PAYMENT_SESSION_NOT_FOUND:
          dispatch({ 
            type: SET_APP_ERROR,
            payload: errorHandling(statusesServer.PAYMENT_SESSION_NOT_FOUND),
          });
          break;
        case statusesServer.WATER_MACHINE_IS_NOT_RESPONDING:
          dispatch({ 
            type: SET_APP_ERROR,
            payload: errorHandling(statusesServer.WATER_MACHINE_IS_NOT_RESPONDING),
          });
          break;
        case statusesServer.PAYMENT_NOT_APPROVED:
          dispatch({ 
            type: SET_APP_ERROR,
            payload: errorHandling(statusesServer.PAYMENT_NOT_APPROVED),
          });
          break;
        case statusesServer.OK:
          dispatch(setAppPaymentSuccess(true))
          break;
        default:
          dispatch({ 
            type: SET_APP_ERROR,
            payload: errorHandling(parsedData.status),
          });
      }
    } catch(e) {  
      dispatch({ 
        type: SET_APP_ERROR,
        payload: errorHandling(statusesClient.UNDEFINED_ERROR),
      });
    } finally {
      dispatch(setAppIsLoading({
        isLoading: false,
        loadingMessage: '',
      }));
    }
  }
}