import React from 'react';
import PropTypes from "prop-types";

const ModalWindow = ({ onClose, onApply, msgApplyBtn, msgCloseBtn, children, title }) => {
  const handleApply = () => {
    onApply();
    onClose();
  }

  return (
    <div className="modal-window-wrapper">
      <div className="modal-window box">
        <div className="modal-window-content">
          <h1 className="modal-window-title">{title}</h1>
          { children }
        </div>
        <div className="modal-window-footer">
          {
            msgCloseBtn ? (
              <input 
                onClick={onClose}
                className="modal-window-btn-close small outline"
                type="button"
                value={msgCloseBtn || 'Закрити'}
              />
            ) : null
          }
          {
            msgApplyBtn ? (
              <input
                onClick={handleApply}
                className="modal-window-btn-apply small"
                type="button"
                value={msgApplyBtn || 'Погоджуюся'}
              />
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

ModalWindow.propTypes = {
  title: PropTypes.string.isRequired,
  msgApplyBtn: PropTypes.string,
  msgCloseBtn: PropTypes.string,
  children: PropTypes.node,
  apply: PropTypes.func,
  close: PropTypes.func
};

export default ModalWindow;
