export const URL_OF_MAIN_SITE = 'https://vodomat.ua/'
export const URL_OF_PAYMENT_PAGE = 'https://www.qr.vodomat.ua/';

export const AUTO_CLOSE_TOAST = 8000;

export const MIN_MONEY_AMOUNT = 1;
export const MAX_LITERS = 100;

export const PREDEFINED_LITERS = [
  { id: 1, value: '1'},
  { id: 2, value: '5'},
  { id: 3, value: '6'},
  { id: 4, value: '10'},
  { id: 5, value: '12'},
  { id: 6, value: '19'},
];

export const MESSAGE_TYPES = {
  modal: 'modal',
  toast: 'toast',
  message: 'message',
  skip: 'skip'
}

const HOST_MACHINE_API = 'https://vpc.finsystem.net/payments/rest';
const HOST_PROCESSING_API = 'https://www.qr.vodomat.ua/api';


export const getWaterMachineInfoLink = (waterMachineId) => {
  return `${HOST_MACHINE_API}/internetAcquiring/info?automateNumber=${waterMachineId}`;
}

export const getWaterMachineWaterAvailableLink = (waterMachineId) => {
  return `${HOST_MACHINE_API}/internetAcquiring/status?automateNumber=${waterMachineId}`;
}

export const setMoneyIntoWaterMachineLink = () => {
  return `${HOST_PROCESSING_API}/payment`;
}

export const getPaymentConfigLink = () => {
  return `${HOST_PROCESSING_API}/purchase-config`;
}